import * as React from 'react'

import { useQuery } from '@apollo/client'

import { Loading, SeoHeaders } from 'shared/components'
import { IdentityProofsRenewal as RenewalForm } from 'shared/pages'
import { ACCOUNT_INFORMATION_QUERY } from 'shared/queries'
import { isLoggedIn } from 'shared/services'

import Layout from '../../layouts/onboarding'

import type { AccountInformationData, AccountInformationVars } from 'shared/queries'

const IdentityProofsRenewal = () => {
  const { loading, data } =
    useQuery<AccountInformationData, AccountInformationVars>(ACCOUNT_INFORMATION_QUERY)

  if (loading) {
    return <Loading />
  }

  const pendingKycRenewals = data?.accountInformation.pendingKycRenewals || []

  if (pendingKycRenewals.length === 0) {
    if (isLoggedIn()) {
      window.location.href = '/app/'
    } else {
      window.location.href = '/auth/login/'
    }
    return null
  }

  return (
    <React.Fragment>
      <SeoHeaders title='Actualizar datos de verificación de identidad' />
      <Layout>
        <RenewalForm isBusiness={!!data?.accountInformation.isBusiness} />
      </Layout>
    </React.Fragment>
  )
}

export default IdentityProofsRenewal
